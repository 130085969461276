import { makeAutoObservable } from "mobx";
import type {
  CurrencyInterface,
  PricesInterface,
  MarketsInterface,
} from "helpers/types";

class Store {
  currencies: CurrencyInterface[] | null = null;
  markets: MarketsInterface[] | null = null;
  prices: PricesInterface | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrencies = (currency: CurrencyInterface[]) => {
    this.currencies = currency.sort((a, b) => a.name === 'GXAG' ? -1 : b.name === 'GXAG' ? 1 : 0);
  };

  setMarkets = (markets: any) => {
    this.markets = markets;
  };

  setPrices = (prices: PricesInterface) => {
    this.prices = prices;
  };
}

const currenciesStore = new Store();
export default currenciesStore;
